export default {
  path: "/head-department",
  name: "head-department",
  props: true,
  component: () => import("@/views/LayoutView.vue"),
  children: [
    {
      path: "",
      redirect: "monitoring",
    },

    {
      path: ":dir(monitoring)",
      component: () => import("@/views/head-department/monitoring/PunktMonitoring.vue"),
    },

    {
      path: ":dir(main)",
      component: () => import("@/views/head-department/monitoring/MainMonitoring.vue"),
    },

    {
      path: ":dir(branches)",
      component: () => import("@/views/head-department/monitoring/BranchesMonitoring.vue"),
    },

    {
      path: ":dir(branches)/:id",
      name: "BranchDetails",
      component: () => import("@/views/head-department/monitoring/BranchDetails.vue"),
      props: true
    },

    {
      path: ":dir/(list)*",
      component: () => import("@/components/default/list/DefaultList.vue"),
    },

    {
      path: ":dir/view/:id",
      component: () => import("@/components/default/view/DefaultView.vue"),
    }
  ],
};
